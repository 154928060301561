import SixMedAPI from './SixMedAPI';

class Questions {
  getAllQuestions = callback => {
    SixMedAPI({
      method: 'GET',
      url: '/Questionnaire/GetAllQuestions',
    })
      .then(response => {
        callback({ status: 'success', data: [...response.data?.result] });
      })
      .catch(error => {
        console.log('error occurred in getAllQuestions ' + error);
        callback({ status: 'error' });
      });
  };
}
export default new Questions();
