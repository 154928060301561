import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
  Slider,
  FormGroup,
  FormControlLabel,
  ListItemButton,
  Autocomplete
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Answers from '../../modules/Answers';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Question = ({
  questionObj,
  handleSelectAnswer,
  handleSelectMultiAnswer,
  answerSubmitList,
  snackbar,
  validationvalue
}) => {
  let Checkboxvalue;
  const [question, setQuestion] = useState(undefined);
  console.log(validationvalue);
  const [answer, setAnswer] = useState({});
  const [answers, setAnswers] = useState([]);
  const [answerTexts, setAnswerTexts] = useState([]);
  const [data, setData] = useState([]);
  const [datad, setDatad] = useState([]);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([]);
  var searchinut = "";
  const [valid, setValid] = useState(true);
  var arrayuni = [
    {
        "questionId": 1,
        "answerOptionId": 1,
        "answerOption": "Aberdeen"
    },
    {
        "questionId": 1,
        "answerOptionId": 2,
        "answerOption": "Anglia Ruskin"
    },
    {
        "questionId": 1,
        "answerOptionId": 3,
        "answerOption": "Aston"
    },
    {
        "questionId": 1,
        "answerOptionId": 4,
        "answerOption": "Birmingham"
    },
    {
        "questionId": 1,
        "answerOptionId": 5,
        "answerOption": "Brighton and Sussex"
    },
    {
        "questionId": 1,
        "answerOptionId": 6,
        "answerOption": "Bristol"
    },
    {
        "questionId": 1,
        "answerOptionId": 7,
        "answerOption": "Cardiff"
    },
    {
        "questionId": 1,
        "answerOptionId": 36,
        "answerOption": "Central Lancashire"
    },
    {
        "questionId": 1,
        "answerOptionId": 38,
        "answerOption": "Cambridge"
    },
    {
        "questionId": 1,
        "answerOptionId": 8,
        "answerOption": "Dundee"
    },
    {
        "questionId": 1,
        "answerOptionId": 9,
        "answerOption": "Edge Hill"
    },
    {
        "questionId": 1,
        "answerOptionId": 10,
        "answerOption": "East Anglia"
    },
    {
        "questionId": 1,
        "answerOptionId": 11,
        "answerOption": "Edinburgh"
    },
    {
        "questionId": 1,
        "answerOptionId": 12,
        "answerOption": "Exeter"
    },
    {
        "questionId": 1,
        "answerOptionId": 13,
        "answerOption": "Glasgow"
    },
    {
        "questionId": 1,
        "answerOptionId": 14,
        "answerOption": "Hull York"
    },
    {
        "questionId": 1,
        "answerOptionId": 15,
        "answerOption": "Imperial"
    },
    {
        "questionId": 1,
        "answerOptionId": 16,
        "answerOption": "Keele"
    },
    {
        "questionId": 1,
        "answerOptionId": 17,
        "answerOption": "Kent and Medway"
    },
    {
        "questionId": 1,
        "answerOptionId": 18,
        "answerOption": "King's College"
    },
    {
        "questionId": 1,
        "answerOptionId": 19,
        "answerOption": "Lancaster"
    },
    {
        "questionId": 1,
        "answerOptionId": 20,
        "answerOption": "Leeds"
    },
    {
        "questionId": 1,
        "answerOptionId": 21,
        "answerOption": "Leicester"
    },
    {
        "questionId": 1,
        "answerOptionId": 22,
        "answerOption": "Lincoln"
    },
    {
        "questionId": 1,
        "answerOptionId": 23,
        "answerOption": "Liverpool"
    },
    {
        "questionId": 1,
        "answerOptionId": 24,
        "answerOption": "Manchester"
    },
    {
        "questionId": 1,
        "answerOptionId": 25,
        "answerOption": "Newcastle"
    },
    {
        "questionId": 1,
        "answerOptionId": 26,
        "answerOption": "Nottingham"
    },
    {
        "questionId": 1,
        "answerOptionId": 37,
        "answerOption": "Oxford"
    },
    {
        "questionId": 1,
        "answerOptionId": 27,
        "answerOption": "Plymouth"
    },
    {
        "questionId": 1,
        "answerOptionId": 28,
        "answerOption": "Queen Mary"
    },
    {
        "questionId": 1,
        "answerOptionId": 29,
        "answerOption": "Queen's Belfast"
    },
    {
        "questionId": 1,
        "answerOptionId": 30,
        "answerOption": "Sheffield"
    },
    {
        "questionId": 1,
        "answerOptionId": 31,
        "answerOption": "Southampton"
    },
    {
        "questionId": 1,
        "answerOptionId": 32,
        "answerOption": "St Andrews"
    },
    {
        "questionId": 1,
        "answerOptionId": 33,
        "answerOption": "St George's"
    },
    {
        "questionId": 1,
        "answerOptionId": 34,
        "answerOption": "Sunderland"
    },
    {
        "questionId": 1,
        "answerOptionId": 35,
        "answerOption": "UCL"
    },
    
];
  const [filteredData,setFilteredData] = useState([]);
  const [checkboxclickideontknow, setcheckboxclickideontknow] = useState(false);
  
  useEffect(() => {
    console.log("////////////////");
    setData(arrayuni);
    let questionOptions = { ...questionObj };
    let answersFromSubmitList = answerSubmitList?.filter(
      a => a.questionId == questionObj.questionId
    );
    if (answersFromSubmitList.length > 0) {

      if (!questionObj.isMultipleSelectionAllowed) {
        setAnswer(answersFromSubmitList[0]);
        setAnswerTexts(answersFromSubmitList[0].answerOption);
      } else {
        setAnswers(answersFromSubmitList?.map(a => a.answerOptionId));
        setAnswerTexts(answersFromSubmitList?.map(a => a.answerOption));
      }
    }

    Answers.getAnswersByQId(questionObj.questionId, response => {
      if (response.status === 'success') {
        {
          let answerOptions = [...response.data];
          if (questionObj.questionId == 8) {
            let homeOrIntrStudent = answerSubmitList.find(
              a => a.questionId == 2
            );
            if (homeOrIntrStudent?.answerOption == 'Home') {
              answerOptions = answerOptions.filter(a => a.answerOptionId != 84);
            }
          }
          questionOptions.options = answerOptions;
          setQuestion(questionOptions);
        }
      }
    });
  }, []);

  const handleMultipleOptionsChange = event => {
    let multiAnswers = event.target.value;
    // console.log(multiAnswers);
    if (multiAnswers.length < 5) {
      setAnswers(multiAnswers);
      let newAnswersList = multiAnswers.map(value => ({
        questionId: question.questionId,
        answerOptionId: value,
        answerOption: getAnswerOption(value),
      }));
      let answerTextVals = newAnswersList.map(a => a.answerOption);
      setAnswerTexts(answerTextVals);
      handleSelectMultiAnswer(newAnswersList);
    } else if (answers.length > multiAnswers.length) {
      setAnswers(multiAnswers);
    } else {
      snackbar({
        open: true,
        error: true,
        message: 'Only 4 universities can be selected',
      });
    }
  };

  const handleSearch = (event) =>{
    // searchinut = "asd";
    // alert(searchinut);
    setDatad(arrayuni);
    console.log(event.target.value);
    let value = event.target.value.toLowerCase();
    setInput(event.target.value);
    if(event.target.value == ""){
      console.log(arrayuni);
      setData(arrayuni)
    }else{
      setData([]);
    }
    var datafound = [];
    data.filter(val=>{
      if(val.answerOption.toUpperCase().includes(event.target.value.toUpperCase())){
        console.log(val);
        datafound.push(val)
        setData(datafound)
        console.log();
      }
    })
    // setFilteredData(arrayuni);
    // console.log(event.value);
    // let value = event.value.toLowerCase();
    // let result = [];
    // console.log(value);
    // result = arrayuni.filter((data) => {
    //   // return data.search(value) != -1;
    //   console.log(data.search(value) != -1);
    // });
    // setFilteredData(result);
    // console.log(result);
    // console.log(result);
  }

  const handleOptionsAnswer = event => {
    let answerObj = {
      questionId: question.questionId,
      answerOptionId: event.target.value,
      answerOption: getAnswerOption(event.target.value),
    };
    setAnswer(answerObj);
    handleSelectAnswer(answerObj, true);
  };

  const handleTextAnswer = event => {
    let valid = handleValidation(event);
    let answerOptionId =
      question.options.length > 0 ? question.options[0].answerOptionId : '';
    let answerObj = {
      questionId: question.questionId,
      answerOption: event.target.value,
      answerOptionId: answerOptionId,
    };
    setAnswer(answerObj);
    if (valid) {
      handleSelectAnswer(answerObj, true);
    } else {
      handleSelectAnswer(answerObj, false);
    }
  };

  const handleRangeAnswer = event => {
    let answerOptionId =
      question.options.length > 0 ? question.options[0].answerOptionId : '';
    let answerObj = {
      questionId: question.questionId,
      answerOption: JSON.stringify(event.target.value),
      answerOptionId: answerOptionId,
    };
    setAnswer(answerObj);
    handleSelectAnswer(answerObj, true); 
  };

  const handleRangeAnswerSecond = event => {
    let answerOptionId =
      question.options.length > 0 ? question.options[0].answerOptionId : '';
     console.log(event.target.value+'.0');
     let value = JSON.stringify(event.target.value)
     if(JSON.parse(event.target.value) % 1 == 0){
       value = value+'.0'
      }
     let answerObj = {
          questionId: question.questionId,
          answerOption: value,
          answerOptionId: answerOptionId,
        };
        setAnswer(answerObj);
        handleSelectAnswer(answerObj, true);
  };

  const handleRangeAnswertest = event =>{
    if(checkboxclickideontknow == false){
      setcheckboxclickideontknow(true);
    }else{
      setcheckboxclickideontknow(false);
    }
    let answerOptionId =
      question.options.length > 0 ? question.options[0].answerOptionId : '';
     let answerObj = {
      questionId: question.questionId,
      answerOption: "0",
      answerOptionId: answerOptionId,
    };
    console.log(event.target.value);
    setAnswer(answerObj);
    handleSelectAnswer(answerObj, true); 
  }

  const handleRangeAnswertestSecond = event =>{
    if(checkboxclickideontknow == false){
      setcheckboxclickideontknow(true);
    }else{
      setcheckboxclickideontknow(false);
    }
    let answerOptionId =
      question.options.length > 0 ? question.options[0].answerOptionId : '';
     let answerObj = {
      questionId: question.questionId,
      answerOption: "0.0",
      answerOptionId: answerOptionId,
    };
    console.log(event.target.value);
    setAnswer(answerObj);
    handleSelectAnswer(answerObj, true); 
  }

  const getAnswerOption = value => {
    let answerObj = question.options.find(i => i.answerOptionId == value);
    return answerObj?.answerOption;
  };


  const handleValidation = e => {
    let reg = new RegExp('[a-z]');
    if (question.questionFormatType == 'Texta')
      reg = new RegExp('^[A-Za-z]{2,20}$');
    else if (question.questionFormatType == 'Phone')
      reg = new RegExp(`^(\\+?[0-9]\\d*)$`);
    else if (question.questionFormatType == 'Email')
      reg = new RegExp(`^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$`);
    else if (question.questionFormatType == 'Integer')
      reg = new RegExp(`^(0|[1-9]\\d*)$`);
    else if (question.questionFormatType == 'Decimal')
      reg = new RegExp(`^[0-9]\\.[0-9]+$`);

    let validation = reg.test(e.target.value);
    console.log(validation)
    if (!validation) {
      if (question.questionFormatType == 'Texta')
        snackbar({
          open: true,
          error: true,
          message: 'Please enter valid text value',
        });
      else if (question.questionFormatType == 'Phone')
        snackbar({
          open: true,
          error: true,
          message: 'Please enter valid phone number',
        });
      else if (question.questionFormatType == 'Email')
        snackbar({
          open: true,
          error: true,
          message: 'Please enter valid email',
        });
      else if (question.questionFormatType == 'Integer')
        snackbar({
          open: true,
          error: true,
          message: 'Please enter valid integer',
        });
      else if (question.questionFormatType == 'Decimal')
        snackbar({
          open: true,
          error: true,
          message: 'Please enter valid decimal number & less than 10.0',
        });
    }
    setValid(validation);
    return validation;
  };

  return (
    <>
      {question ? (
        <Grid
          key={question.questionId}
          item
          md={6}
          xs={12}
          alignContent='center'
        >
          {' '}
          <Typography
            variant='subtitle1'
            style={{ minHeight: '60px', color: '#626262' }}
          >
            {question.questionVerbiage}
          </Typography>
          {question.questionType == 'TextField' ? (
          <FormControl style={{ width: '100%' }}>
            <TextField
              onChange={handleTextAnswer}
              value={answer?.answerOption}
              fullWidth
              error={!valid}
              required={true}
              size='small' 
            />
            {validationvalue && !answer?.answerOption && <p style={{color: 'red',
                position: 'absolute',
                top: '35px',fontSize: '11px'}}>*Please fill this field</p>} 
           </FormControl>     
          ) :question.questionType == 'Range' ? (
            <FormGroup>
            {!checkboxclickideontknow ? (
              <Slider style={{ color: '#d82975'}}
                size="small"
                aria-label="Always visible"
                valueLabelDisplay="on"
                min={1200}
                max={3600}
                defaultValue={2400}
                disabled = {checkboxclickideontknow}
                onChange={handleRangeAnswer}
                value = {answer?.answerOption}
              />
              ):(
               <Slider style={{ color: '#9e9e9e'}}
                size="small"
                aria-label="Always visible"
                valueLabelDisplay="on"
                min={1200}
                max={3600}
                defaultValue={2400}
                disabled = {checkboxclickideontknow}
                onChange={handleRangeAnswer}
                value = {answer?.answerOption}
              />
              )}
              <FormControlLabel control={<Checkbox onChange={handleRangeAnswertest} style={{ color: '#d82975' }} />} label="I don’t know" />
            </FormGroup>
          ) :question.questionType == 'RangeBMAT' ? (
            <FormGroup>
            {!checkboxclickideontknow ? (
              <Slider style={{ color: '#d82975'}}
                size="small"
                min={1}
                max={9}
                defaultValue={5}
                aria-label="Always visible"
                valueLabelDisplay="on"
                step={0.1}
                disabled = {checkboxclickideontknow}
                onChange={handleRangeAnswerSecond}
                value = {answer?.answerOption}
              />
            ):(
              <Slider style={{ color: '#9e9e9e'}}
                size="small"
                min={1}
                max={9}
                aria-label="Always visible"
                valueLabelDisplay="on"
                step={0.1}
                defaultValue={5}
                disabled = {checkboxclickideontknow}
                onChange={handleRangeAnswerSecond}
                value = {answer?.answerOption}
              />
            )}
              <FormControlLabel control={<Checkbox onChange={handleRangeAnswertestSecond} style={{ color: '#d82975' }} />} label="I don’t know" />
            </FormGroup>
          ) : question.questionType == 'MultipleChoice' &&
            question.isMultipleSelectionAllowed ? (
            <FormControl style={{ width: '100%' }}>
               <Select
                variant='outlined'
                fullWidth
                labelId='demo-multiple-name-label'
                id='multiple-options-id'
                multiple
                value={answers}
                onChange={handleMultipleOptionsChange}
                MenuProps={MenuProps}
                renderValue={selected => answerTexts.join(', ')}
                size='small'
                sx={{
                  '&:hover': {
                    '&& fieldset': {
                      border: '2px solid #d82975',
                    },
                  },
                }}
              >
              
              <input style={{    width: '100%',
                height: '44px',
                boxShadow: '2px 5px 18px -10px',
                border: 'none',
                paddingLeft: '25px'}} type="text" placeholder="Search here ..." onChange={(event) =>handleSearch(event)} />    
                
                {data?.map((option,index) => (
                  
                  <ListItemButton
                    key={option.answerOptionId}
                    id={option.answerOptionId}
                    value={option.answerOptionId}
                  >
                    <Checkbox
                      checked={answers?.includes(option.answerOptionId)}
                      sx={{
                        color: '#d82975',
                        '&.Mui-checked': {
                          color: '#d82975',
                        },
                      }}
                    />
                    <p>{option.answerOption}</p>
                    
                  </ListItemButton>
                  ))}      

              </Select> 
              {!validationvalue && answers < 5 && <p style={{
                fontSize: "12px",
                position: "absolute",
                marginTop: "-35px",color: '#d82975'
              }}>Select up to 4 universities</p>}
              {validationvalue && answers < 1 && <p style={{color: 'red',
                position: 'absolute',
                top: '35px',fontSize: '11px'}}>*Please fill this field</p>} 
            </FormControl>
          ) : question.questionType == 'MultipleChoice' ? (
            <FormControl style={{ width: '100%' }}>
              <Select
                variant='outlined'
                fullWidth
                labelId='demo-single-name-label'
                id='single-options-id'
                value={answer?.answerOptionId}
                onChange={handleOptionsAnswer}
                MenuProps={MenuProps}
                size='small'
                sx={{
                  '&:focus': {
                    '&& fieldset': {
                      border: '2px solid #d82975',
                    },
                  },
                  '&:hover': {
                    '&& fieldset': {
                      border: '2px solid #d82975',
                    },
                  },
                }}
              >
                
                {question.options?.map(option => (
                  <MenuItem
                    key={option.answerOptionId}
                    value={option.answerOptionId}
                    name={option.answerOption}
                  >
                    {option.answerOption}
                  </MenuItem>
                ))}
              </Select>
              {validationvalue && !answer?.answerOptionId && <p style={{color: 'red',
    position: 'absolute',
    top: '35px',fontSize: '11px'}}>*Please fill this field</p>}
            </FormControl>
          ) : null}
        </Grid>
      ) : (
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={12} md={6}>
            <Box>
              <Skeleton animation='wave' />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Skeleton animation='wave' />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Question;
