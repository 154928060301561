import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Button,
  Snackbar,
  Typography,
  Paper,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.svg';
import username from '../assets/Group 31.png'
import username1 from '../assets/Group 32.png'
import Rectangle38 from '../assets/Rectangle 38.png'
import Group33 from '../assets/Group 33.png'
import Group332 from '../assets/Group 33 (2).png'
import Group333 from '../assets/Group 33 (3).png'
import Ellipse20 from '../assets/Ellipse 20.png'
import Ellipse12 from '../assets/Ellipse 12.png'

import r40 from '../assets/r40.png'
import r50 from '../assets/r50.png'
import r60 from '../assets/r60.png'
import r100 from '../assets/r100.png'



import p40 from '../assets/p40.png'
import p50 from '../assets/p50.png'
import p100 from '../assets/p100.png'
import SixMedConfig from '../config/six-med-config';
import Content from './Content';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';

let pass = "hello";
// import html2pdf from 'html2pdf.js';

const doc = new jsPDF();
const Result = () => {
  let navigateTo = useNavigate();
  const location = useLocation();
  let resultcheck = "okay";
  let resultVal="";
  var percentagelessthentenvalueset = "";
  const [result, setResult] = useState({});
  const [testuniverstyanswer, setTestuniverstyanswer] = useState();
  const [expanded, setExpanded] = React.useState('');
  const [probability, setProbability] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState({ open: false });
  // const [sendingEmail, setSendingEmail] = React.useState(false);

  useEffect(() => {
    resultVal = location.state;
    // console.log(resultVal)
    // let testsendvalue = 27.35
    setSnackbar({
      open: true,
      error: false,
      message: '6med result sent to your email.',
    });
    console.log(resultVal.universitiesSuccessScores[0]);
    console.log(resultVal.answersList);
    // percentagelessthentenvalueset = resultVal.answersList[12].answerOption;
    // alert("this.");
    // alert(percentagelessthentenvalueset);
    console.log(resultVal);
    for(let i=0;i<resultVal.universitiesSuccessScores.length;i++){
      if(resultVal.universitiesSuccessScores[i].avergageUCATScore != 0){
        for(let k=0;k<resultVal.answersList.length;k++){
          if(resultVal.answersList[k].questionId == 12){
            percentagelessthentenvalueset = resultVal.answersList[k].answerOption
             console.log(JSON.parse(resultVal.answersList[k].answerOption));
             console.log(resultVal.universitiesSuccessScores[i].avergageUCATScore);
             if(JSON.parse(resultVal.answersList[k].answerOption <= resultVal.universitiesSuccessScores[i].avergageUCATScore)){
                resultcheck = "zero"
                console.log(resultcheck);
             }
          }
        }
      }
    }
    for(let i=0;i<resultVal.universitiesSuccessScores.length;i++){
      if(resultVal.universitiesSuccessScores[i].averageBMATScore != 0){
        for(let k=0;k<resultVal.answersList.length;k++){
          if(resultVal.answersList[k].questionId == 11){
             console.log(JSON.parse(resultVal.answersList[k].answerOption));
             console.log(resultVal.universitiesSuccessScores[i].averageBMATScore);
             if(JSON.parse(resultVal.answersList[k].answerOption <= resultVal.universitiesSuccessScores[i].averageBMATScore)){
                resultcheck = "zero"
                console.log(resultcheck);
             }
          }
        }
      }
    }
    for(let i=0;i<resultVal.universitiesSuccessScores.length;i++){
      if(resultVal.universitiesSuccessScores[i].minIB != 0){
        for(let k=0;k<resultVal.answersList.length;k++){
          if(resultVal.answersList[k].questionId == 7){
             console.log(JSON.parse(resultVal.answersList[k].answerOption));
             console.log(resultVal.universitiesSuccessScores[i].averageBMATScore);
             if(JSON.parse(resultVal.answersList[k].answerOption <= resultVal.universitiesSuccessScores[i].minIB)){
                resultcheck = "zero"
                console.log(resultcheck);
             }
          }
        }
      }
    }  
    console.log(resultcheck); 
    // if(resultcheck == "okay"){
      let probPercentage = 1;
      console.log("ohooooo!")
      
      for(let i=0;i<resultVal.universitiesSuccessScores.length;i++){
        console.log("111111111112222222222222222222222222");
        console.log(resultVal.universitiesSuccessScores[i]);
        console.log("111111111112222222222222222222222222");
        probPercentage *= multiplyScores(resultVal.universitiesSuccessScores[i]);
      }
      console.log(probPercentage);
      probPercentage = (100-(probPercentage*100))
      console.log("////////////////////////////////////");
      setProbability(probPercentage.toFixed(2));
    // }else{
    //   console.log("hello");
    //   for(let i=0;i<resultVal.universitiesSuccessScores.length;i++){
    //     let probPercentage = multiplyScores(10);
    //     setProbability(10);
    //     console.log(probPercentage);
    //   }
    // }
    // console.log(probPercentage);

    let hasALevel = resultVal.answersList.find(a => a.questionId == 5);
    if (hasALevel && hasALevel.answerOption == 'No') {
      resultVal.hasNotALevel = true;
    } else {
      resultVal.hasNotALevel = false;
    }

    setResult(resultVal);
    setTimeout(() => {
      _exportPdf1(resultVal);
    }, 2000);
  }, []);

  // const _exportPdf = resultVal => {
  //   const pdf = new jsPDF('l', 'mm', 'a4', true);
  //   var source = document.querySelector('#resultPageMainDiv');
  //   window.html2canvas = html2canvas;
  //   pdf.setFontSize(12);
  //   pdf
  //     .html(source.innerHTML, {
  //       x: 10,
  //       y: 10,
  //     })
  //     .then(() => {
  //       //  pdf.save('Report5.pdf');
  //       if (resultVal?.applicantDto) {
  //         sendEmail(pdf, resultVal?.applicantDto?.email);
  //       }
  //       // pdf.output('dataurlnewwindow');
  //     });
  // };



  const _exportPdf1 = resultVal => {

    const domElement = document.querySelector('#resultPageMainDiv')
    if (resultVal?.applicantDto) {
      console.log("///////////////////////////////");
      console.log(doc);
      console.log("///////////////////////////////");
      sendEmail(null, resultVal?.applicantDto?.email,resultVal?.applicantDto?.applicantId);
    }
    // const string = renderToString(<Content />);
    // html2canvas(domElement,{onclone:(document) =>{document.querySelector('#contentpage').style.display = 'block';document.querySelector('.snackbar_green').style.display = 'none'},
    //   scale: 2,
    //   pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' } 
    // }).then(canvas => {
      // const contentDataURL = canvas.toDataURL('image/png');
      // var  margin = 20;
      // var imgWidth = 212 - 2 * margin;
      // var pageHeight = 297;
      // var imgHeight = (canvas.height * imgWidth) / canvas.width;
      // var heightLeft = imgHeight; 
      // var doc = new jsPDF('p', 'mm', 'a4', true);
      // var position = 0;


      
      // doc.text(35, 25, 'Paranyan loves jsPDF');
      // doc.fromHTML(, 15, 15, {
      //     "width":170
      // });
      // doc.fromHTML(domElement);
      // doc.textWithLink('Paranyan loves jsPDF', 10, 10, { url: 'http://www.google.com' });
      // doc.text(0, 0, canvas);

      // doc.addImage(
      //   contentDataURL,
      //   'PNG',
      //   margin,
      //   position,
      //   imgWidth,
      //   imgHeight,
      // );

      // heightLeft -= pageHeight;

      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   doc.addPage();
      //   doc.addImage(
      //     contentDataURL,
      //     'PNG',
      //     margin,
      //     position,
      //     imgWidth,
      //     imgHeight
      //   );
      //   heightLeft -= pageHeight;
      // }
      
      
    // });
  };

  const _exportPdf_org = resultVal => {
   
    html2canvas(document.querySelector('#resultPageMainDiv'), {
      scale: 2,
      retina: true,
      pagesplit: true,
    }).then(canvas => {
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const imgData = canvas.toDataURL('image/png');
      console.log(doc.internal.pageSize.getWidth());
      var HTML_Width = doc.internal.pageSize.getWidth();

      var HTML_Height = doc.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'PNG', 20, 20, HTML_Width, HTML_Height + 300);
     
    });
  };

  const sendEmail = (pdfReport, email,applicantId) => {
    console.log(pdfReport);
    console.log(email);
    var formData = new FormData();
    formData.append('PDFReport', pdfReport);
    formData.append('Email', email);
    formData.append('ApplicantId', applicantId);
    axios
      .post(SixMedConfig.apiURL + '/Applicant/SendEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ApiKey: SixMedConfig.apiKey,
        },
      })
      .then(response => {
        // setSendingEmail(false);
        // setSnackbar({
        //   open: true,
        //   error: false,
        //   message: '6med result sent to your email.',
        // });
      })
      .catch(error => {
        // setSendingEmail(false);

        console.log('success' + error);
      });
  };

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const multiplyScores = scores => {
    var value = 1;
    value = value * ((100 - scores.applicantSuccessScore) / 100);
    console.log(value)
    return value;
  };

  return (
    <>
    <div >
      <div id='resultPageMainDiv'>
        <Box>
          <Box textAlign='center'>
            <img
              src={Logo}
              onClick={e => navigateTo('/')}
              alt='Logo image'
              height='50px'
            />
          </Box>
          <Typography style={{fontWeight: 'bold',fontSize: '35px'}} variant='h4' align='center' mb={4}>
            Medical School Success Calculator
          </Typography>
          {/* <Box className='wrapper__Box'>
            <Box className='wrapper__Box--left'>Calculated For</Box>
            <Box className='wrapper__Box--right'>{`${result?.applicantDto?.firstName} ${result?.applicantDto?.lastName}`}</Box>
          </Box> */}

          <div class="maindivcal"> 
            <Box class="calculatedfor">
              <img class="usernameimage" src={username}/>
              <box class="usernametext">
                <span style={{color:'#9DC8D9'}}>Calculated For</span><br />
                <span style={{fontWeight: '700'}}>{`${result?.applicantDto?.firstName} ${result?.applicantDto?.lastName}`}</span>
              </box>
            </Box>
            <Box class="calculatedfor">
            <img class="usernameimage" src={username1}/>
              <box class="usernametext">
                <span style={{color:'#9DC8D9'}}>Dated</span><br />
                <span style={{fontWeight: '700'}}>{moment(new Date()).format('DD MMM YYYY')}</span>
              </box>
            </Box>
          </div>
          <h3 class="unititle">Universities</h3>
          <p class="titletxt">We estimate your success rate for <br /> Medicine at Universities to be:</p>
          {/* {result?.universitiesSuccessScores?.length > 0 ? (
            <Box className='wrapper__Box'>
              <Box className='wrapper__Box--left'>University</Box>
              <Box className='wrapper__Box--accordian'>
                <Accordion
                  expanded={expanded === 'panel0'}
                  onChange={handleChange('panel0')}
                >
                  <AccordionSummary>
                    <Box>
                      {result?.universitiesSuccessScores[0].universityName}
                    </Box>
                    {expanded === 'panel0' ? (
                      <Box className='plus-cross--btn'>x</Box>
                    ) : (
                      <Box className='plus-cross--btn'>+</Box>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul style={{ margin: '0', padding: '0 0 0 10px' }}>
                      <li>
                        Application Test:{' '}
                        <b>
                          {result?.universitiesSuccessScores[0].applicationTest}
                        </b>{' '}
                      </li>
                      <li>
                        Required A-Level:{' '}
                        <b>{result?.universitiesSuccessScores[0].minALevel}</b>{' '}
                      </li>
                      <li>
                        Required IB:{' '}
                        <b>{result?.universitiesSuccessScores[0].minIB}</b>
                      </li>
                      <li>
                        Interview Style:{' '}
                        <b>
                          {result?.universitiesSuccessScores[0].interviewStyle}
                        </b>
                      </li>
                      <li>
                        Review Link:{' '}
                        <a
                          style={{ color: '#FFF' }}
                          href={
                            result?.universitiesSuccessScores[0].courseLink ||
                            SixMedConfig.sixMed
                          }
                          target='_blank'
                        >
                          {result?.universitiesSuccessScores[0].courseLink ||
                            SixMedConfig.sixMed}
                        </a>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          ) : null} */}

          {/* {result?.universitiesSuccessScores?.map((univ, index) => {
            if (index > 0) {
              return (
                <Box className='wrapper__Box'>
                  <Box
                    className='wrapper__Box--left'
                    style={{ backgroundColor: 'transparent' }}
                  ></Box>

                  <Box className='wrapper__Box--accordian'>
                    <Accordion
                      expanded={expanded === 'panel' + index}
                      onChange={handleChange('panel' + index)}
                    >
                      <AccordionSummary
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                      >
                        <Box>{univ.universityName}</Box>
                        {expanded === 'panel' + index ? (
                          <Box className='plus-cross--btn'>x</Box>
                        ) : (
                          <Box className='plus-cross--btn'>+</Box>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul style={{ margin: '0', padding: '0 0 0 10px' }}>
                          <li>
                            Application Test: <b>{univ.applicationTest}</b>{' '}
                          </li>
                          <li>
                            Minimum A-Level: <b>{univ.minALevel}</b>{' '}
                          </li>
                          <li>
                            Minimum IB: <b>{univ.minIB}</b>
                          </li>
                          <li>
                            Interview Style: <b>{univ.interviewStyle}</b>
                          </li>
                          <li>
                            Review Link:{' '}
                            <a
                              href={univ.courseLink || SixMedConfig.sixMed}
                              style={{ color: '#FFF' }}
                              target='_blank'
                            >
                              {univ.courseLink || SixMedConfig.sixMed}
                            </a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              );
            }
          })}

          <Box className='wrapper__Box'>
            <Box className='wrapper__Box--left'>Date</Box>
            <Box className='wrapper__Box--right'>
              {moment(new Date()).format('DD MMM YYYY')}
            </Box>
          </Box> */}

          <br></br>
          <br></br>
          {/* <div className='wrapper__Box'>
            <Typography className='calculation__text' align='center' mb={4}>
              We estimate your chances for Medicine at Universities to be:{' '}
            </Typography  >
          </div> */}
          {result?.universitiesSuccessScores?.length == 1 ? null : (
            <>
            {console.log('...........................................')}
            {console.log(result?.universitiesSuccessScores)}
            <div className='wrapper__Box'>
              <Card 
                className='card'
                style={{ textAlign: 'center',width: '320px',height:'350px',borderRadius: '20px' }}
              >
                <CardContent>
                  {probability > 50 ?(
                        <img src={r60}/>
                        ):null}
                        {probability < 50 ?(
                        <img src={r40}/>
                        ):null}
                        {probability == 50 ?(
                        <img src={r50}/>
                        ):null}
                        {probability == 100 ?(
                        <img src={r100}/>
                  ):null}
                  <div style={{color:'#03688F'}} className='card__text2'>
                    {/* {alert(JSON.stringify(result?.universitiesSuccessScores))} */}
                    {/* {alert(percentagelessthentenvalueset)} */}
                    <span>{probability < 10 ? '10.0' :probability}%</span>
                  </div>
                  <Typography style={{fontSize: '23px',color:'#000'}} className='card__text--small'>
                    Overall Chance
                  </Typography>
                </CardContent>
              </Card>
            </div>
            </>
          )}

          <div className='wrapper__Box'>
            <div className='grid-container'>
              {result?.universitiesSuccessScores?.map((univ, index) => {
                console.log(univ);
                console.log("working");
                for(let i=0;i<result.universitiesSuccessScores.length;i++){
                  if(result.universitiesSuccessScores[i].avergageUCATScore != 0){
                    for(let k=0;k<result.answersList.length;k++){
                      if(result.answersList[k].questionId == 12){
                         console.log(JSON.parse(result.answersList[k].answerOption));
                         console.log(result.universitiesSuccessScores[i].avergageUCATScore);
                         if(JSON.parse(result.answersList[k].answerOption <= result.universitiesSuccessScores[i].avergageUCATScore)){
                          //  univ.applicantSuccessScore = 0
                         }
                      }
                    }
                  }
                }

                for(let i=0;i<result.universitiesSuccessScores.length;i++){
                  if(result.universitiesSuccessScores[i].averageBMATScore != 0){
                    for(let k=0;k<result.answersList.length;k++){
                      if(result.answersList[k].questionId == 11){
                         console.log(JSON.parse(result.answersList[k].answerOption));
                         console.log(result.universitiesSuccessScores[i].averageBMATScore);
                         if(JSON.parse(result.answersList[k].answerOption <= result.universitiesSuccessScores[i].averageBMATScore)){
                          //  univ.applicantSuccessScore = 0
                         }
                      }
                    }
                  }
                }

                for(let i=0;i<result.universitiesSuccessScores.length;i++){
                  if(result.universitiesSuccessScores[i].minIB != 0){
                    for(let k=0;k<result.answersList.length;k++){
                      if(result.answersList[k].questionId == 7){
                         console.log(JSON.parse(result.answersList[k].answerOption));
                         console.log(result.universitiesSuccessScores[i].averageBMATScore);
                         if(JSON.parse(result.answersList[k].answerOption <= result.universitiesSuccessScores[i].minIB)){
                            // univ.applicantSuccessScore = 0
                         }
                      }
                    }
                  }
                }  

                return (
                  <div className='grid-item'>
                    <Card style={{width: '295px',height:'299px'}} className='card'>
                      <CardContent>
                        {univ.applicantSuccessScore > 50 ?(
                        <img src={p50}/>
                        ):null}
                        {univ.applicantSuccessScore < 50 ?(
                        <img src={p40}/>
                        ):null}
                        {univ.applicantSuccessScore == 100 ?(
                        <img src={p100}/>
                        ):null}

                        {console.log("............```````.````````")}  
                        {console.log(univ)}  
    
                        <Typography style={{marginTop: '-112px',marginBottom: '100px'}} className='card__text'>
                          {univ.applicantSuccessScore < 10 ? (
                            univ.applicantSuccessScore != 0 ? univ.avergageUCATScore < percentagelessthentenvalueset ? "<10%" : univ.applicantSuccessScore+"%" : "<10%"
                          ):
                            univ.applicantSuccessScore+"%"
                          }
                        </Typography>
                        
                        <Typography style={{fontSize:'22px',color:'#03688F'}} className='card__text--small'>
                          University of {univ.universityName}
                        </Typography>
                       
                      </CardContent>
                    </Card>
                    {(univ.applicationTest !== "BMAT") ?
                    <Accordion style={{width: '295px'}}>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography><img class="Rectangle38" src={Rectangle38}/></Typography>
                          </AccordionSummary>        
                          <AccordionDetails>
                            <Typography style={{textAlign:'left'}}>
                              {univ.applicationTest ?(
                                <p style={{color:'#006890'}}>Application Test: <span style={{fontWeight:'bold'}}>{univ.applicationTest}</span></p>
                              ):null}
                              {univ.minALevel ?(
                                <p style={{color:'#006890'}}>Required A-level: <span style={{fontWeight:'bold'}}>{univ.minALevel}</span></p>
                              ):null}
                              {univ.minIB ?(
                                <p style={{color:'#006890'}}>Required IB: <span style={{fontWeight:'bold'}}>{univ.minIB}</span></p>
                              ):null}
                              {univ.interviewStyle ?(
                                <p style={{color:'#006890'}}>Interview style: <span style={{fontWeight:'bold'}}>{univ.interviewStyle}</span></p>
                              ):null}
                              {univ.courseLink ?(
                                <p style={{color:'#006890'}}>Review Link: <a href={univ.courseLink} style={{fontWeight:'bold',color:'#006890'}}>{univ.courseLink}</a></p>
                              ):null}
                            </Typography>
                          </AccordionDetails> 
                        </Accordion>: <></>}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {/* {result?.hasNotALevel ? (
              <Typography align='center'>
                <a
                  style={{ color: '#3f87aa' }}
                  href={SixMedConfig.aLevel}
                  target='_blank'
                >
                  What A-Levels Do I Need To Be A Doctor?
                </a>
              </Typography>
            ) : null} */}
          </div>
          <div>
          {result? (
            <p className='please-note-text'>
              *Please note this is a calculated estimate based on the data we have
              available.
            </p>
          ):null}
          </div>
          <div id="contentpage" style={{display: 'none'}}>
          {result?.answersList ? (
              <Content 
              answersList={result?.answersList}
              universitiesScores={result?.universitiesSuccessScores}
            />
            ) : null}
          </div>
        </Box>
        {snackbar.open ? (
          <Snackbar
            className={snackbar.error ? `snackbar_red` : `snackbar_green`}
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={event => setSnackbar({ open: false, message: '' })}
            message={'6med result has been sent to your email'}
          />
        ) : null}
      </div>
      
      </div>
    </>
  );
 
};

export default Result;
