import axios from 'axios';
import Auth from './Auth';
import SixMedConfig from '../config/six-med-config';

const SixMedAPI = config => {
    const token = Auth.getToken();
    if (token != null) {
        config.headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            ApiKey: token,
        };
    }

    axios.interceptors.response.use(
        response => {
            return response;
        },
        function (error) {
            if (!error.response) {
                error.response = {
                    data: 'INETRNAL SERVER ERROR',
                    status: 500,
                };
            }
            if (error.response.status === 401) {
                Auth.logout();
                throw error;
            }
            return Promise.reject(error);
        }
    );

    config.baseURL = SixMedConfig.apiURL;
    return axios(config);
};
export default SixMedAPI;
