import SixMedCalculator from './pages/sixMedCalculator';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import './index.css';
import { Routes, Route } from 'react-router-dom';
import Result from './pages/Result';

function App() {
  return (
    <div className='main__div'>
      <CssBaseline />

      <Container fixed maxWidth='lg' className='container'>
        <Routes>
          <Route path='/' element={<SixMedCalculator />} />
          <Route path='/result' element={<Result />} />
        </Routes>
      </Container>
      
    </div>
  );
}

export default App;
