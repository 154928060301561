const SixMed_CONTENT = {
  HIGH_AVG_UNIV:
    'You are applying for a university that has a higher-than-average success rate due to the number of applicants.',
  HOME_APPLICANT:
    'You’re a home applicant! International applicants have a much tougher time getting a place at university.',
  AGE_ABOVE_EIGTEEN:
    'You’re old enough to apply to study medicine. If you were under 18, most medical schools can’t accept you due to patient contact.',
  IB_SCORE_MEET: 'Your IB score meets the university minimum requirements!',
  A_LEVEL_REQUIREMENT: 'You meet the A-level subject requirements!',
  UCAT_SCORE_ABOVE_AVG:
    'The UCAT score you have entered is above the average applicant for this university – this puts you in a good position.',
  BMAT_SCORE_ABOVE_AVG:
    'The BMAT score you have entered is above the average application for this university – this puts you in a good position.',
  UCAT_SCORE_SUCCESS:
    'The UCAT score you have entered is above the average score for a successful applicant to',
  BMAT_SCORE_SUCCESS:
    'The BMAT score you have entered is above the average score for a successful applicant to',
  SCHOOL_TYPE:
    'The type of school you attend tends to have a better chance of getting students into medical school than other types.',
  WORK_EXPERIENCE:
    'It’s great that you’ve managed to get some work experience in the field! It’s always a good idea to get more though as it gives you stuff to talk about in your Personal Statement and at interview.',
  POLAR:
    'You are from a POLAR4+ location – this may have an affect on how admissions tutors review the contextual information with your application.',
  EPQ: 'You’ve taken an EPQ – that’s something that will help you stand out from other applicants so long as it’s relevant to the course. Nicely done!',

  LOW_AVG_UNIV:
    'You are applying for a university that has a lower-than-average success rate due to the number of applicants.',
  INTERNATIONAL_APPLICANT:
    'You’re an international applicant. Unfortunately, international applicants have a much tougher time getting a place at university in the UK.',
  AGE_UNDER_EIGNTEEN:
    'You’re under the standard age to study medicine. As you will be under 18 when the course begins, medical schools face challenges around the legalities of patient contact, making them less likely to accept you.',
  AGE_ABOVE_NINETEEN:
    'You’re older than the “usual” applicant. This isn’t inherently bad, but you should be able to show how you have been working towards your application in this time.',
  IB_SCORE_LOW:
    'Your IB score doesn’t meet the university minimum requirements.',
  A_LEVEL_NOT_MEET: 'You don’t meet the A-level subject requirements.',
  UCAT_SCORE_BELOW_AVG:
    'The UCAT score you have entered is below the average applicant for this university. The UCAT is a significant component of your application, so you should really work on this area.',
  BMAT_SCORE_BELOW_AVG:
    'The BMAT score you have entered is below the average applicant for this university. The BMAT is a significant component of your application, so you should really work on this area.',
  SCHOOL_TYPE_LOW:
    'The type of school you attend tends have lower rates of success than other school types. This is based on a large data set, so has a small impact on you as an individual.',
  WORK_EXPERIENCE_LOW:
    'Work experience is a vitally important aspect of your application. If you still have time before your application, you should do your best to arrange some work experience as not having any will lower your chances.',
  POLAR_LOW:
    'This isn’t truly a negative – you are from a region that has average or higher rates of students going into further education. This means simply means who assesses your application won’t take this into account for your contextual information.',
  EPQ_LOW:
    'You haven’t taken an EPQ – not all students will do this, but the students that do (as long as it’s in a relevant field) will often find themselves to stand out from the competition.',
};
export default SixMed_CONTENT;
