import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Question from '../Question';
const QuestionsScreen = ({
  activeScreen,
  questions,
  submitAnswer,
  handleNextScreen,
  handleBackScreen,
  handleAnswerSubmit,
  answerSubmitList,
  snackbar,
}) => {

  const Questionvalidation = [];
  const [answersList, setAnswersList] = useState([]);
  const [validationvalue, setvalidationvalue] = useState(false);
  useEffect(() => {
    let answeredList = [];
    questions?.forEach(q => {
      let answers = answerSubmitList?.filter(a => a.questionId == q.questionId);
      if (answers.length > 0) {
        answeredList = answeredList.concat(answers);
      }
    });
    setAnswersList(answeredList);
  }, []);

  const handleSelectMultiAnswer = answers => {
    if (answersList?.length > 0) {
      if (answers?.length > 0) {
        let newAnswersList = answersList?.filter(
          a => a.questionId != answers[0].questionId
        );
        newAnswersList = newAnswersList.concat(answers);
        setAnswersList(newAnswersList);
      } else {
        setAnswersList([]);
      }
    } else {
      setAnswersList([...answers]);
    }
  };

  const handleSelectAnswer = (answer, isValid) => {
    let answerIndex = answersList?.findIndex(
      a => a.questionId == answer.questionId
    );
    if (isValid) {
      if (answerIndex == -1) {
        answersList.push(answer);
      } else {
        answersList[answerIndex] = answer;
      }
      setAnswersList(answersList);
    } else {
      if (answerIndex != -1) {
        answersList.splice(answerIndex, 1);
      }
    }
  };

  const handleNext = submit => {
    let containsAll = answersList.filter(
      a => questions.filter(q => q.questionId == a.questionId).length > 0
    );
    console.log(containsAll);
    console.log(questions);
    console.log(answersList);
    for(let i=0;i<containsAll.length;i++){
      if(!Questionvalidation.includes(containsAll[i].questionId)){
         Questionvalidation.push(containsAll[i].questionId); 
      }
    }
    console.log(Questionvalidation)
    if (Questionvalidation.length >= questions.length) {
      setvalidationvalue(false);
      handleNextScreen(answersList);
      if (submit == true) {
        handleAnswerSubmit();
      }
     }else{
      setvalidationvalue(true);
      snackbar({
        open: true,
        error: true,
        message: 'Please fill in all the required fields',
      });
    }
  };

  const handleSubmit = () => {
    handleNext(true);
  };

  const handleBack = () => {
    handleBackScreen();
  };

  return (
    <Grid container spacing={3} alignItems='center'>
      {questions.map(question => {
        return (
          <Question
            key={question.questionId}
            questionObj={question}
            handleSelectAnswer={handleSelectAnswer}
            handleSelectMultiAnswer={handleSelectMultiAnswer}
            answerSubmitList={answerSubmitList}
            snackbar={snackbar}
            validationvalue={validationvalue}
          />
        );
      })}

      <br />
      <Grid item xs={12}>
        <Box className='btn-wrapper'>
          <Button
            className={
              activeScreen == 1 ? 'btn btn--left-disabled' : 'btn btn--left'
            }
            variant='contained'
            onClick={handleBack}
            disabled={activeScreen == 1}
          >
            Back
          </Button>
          {submitAnswer ? (
            <Button
              className='btn btn--right'
              variant='contained'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <Button
              className='btn btn--right'
              variant='contained'
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default QuestionsScreen;
