import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SixMed_CONTENT from '../config/content';

function Content({ answersList, universitiesScores }) {
  const answerExist = answer => {
    return answersList?.some(a =>
      a.answerOption.toUpperCase().includes(answer.toUpperCase())
    );
  };

  const findAnswer = num => {
    let answerFound = answersList.find(a => a.questionId == num);
    return answerFound?.answerOption;
  };

  const hasAnswer = (num, answer) => {
    let answerFound = answersList.find(a => a.questionId == num);
    return answerFound?.answerOption.toUpperCase() == answer.toUpperCase()
      ? true
      : false;
  };

  const hasAnswerGreater = (num, answer) => {
    let answerFound = answersList.find(a => a.questionId == num);
    return answerFound?.answerOption > answer ? true : false;
  };

  return (
    <div style={{marginTop:universitiesScores?.length == 1 ? '150px': universitiesScores?.length == 2? '680px': '380px',marginLeft: '50px'}}>
      {/* <p className='please-note-text'>
        *Please note this is a calculated estimate based on the data we have
        available.
      </p>  */}
      {/* <p style={{marginBottom:universitiesScores?.length < 2 ? '980px': universitiesScores?.length == 2 ? '650px': '480px'}} className='please-note-text1'>
        *Please note this is a calculated estimate based on the data we have
        available.
      </p>
     */}
      <Typography
        variant='h4'
        mb={4}
        fontWeight='600'
        fontSize='30px'
        style={{ color: '#d82975' }}
      >
        Stuff that increases your success rate
      </Typography>
     
      {universitiesScores.map((univ, index, row) => {
        return (
          <>
            {index > 0 ? (
              <>
               {(findAnswer(7) >= univ.minIB) || (findAnswer(12) >= univ.avergageUCATScore &&
            univ.applicationTest == 'UCAT') || (findAnswer(11) >= univ.averageBMATScore &&
            univ.applicationTest == 'BMAT') ? (
                <br />
                ) : null}
              </>
            ) : null}
            {(findAnswer(7) >= univ.minIB) || (findAnswer(12) >= univ.avergageUCATScore &&
            univ.applicationTest == 'UCAT') || (findAnswer(11) >= univ.averageBMATScore &&
            univ.applicationTest == 'BMAT') ? (
              <li style={{textIndent: '-1.5em',
              marginLeft: '10px',
           paddingLeft: '1em'}}>
                <b style={{ color: '#d82975' }}>{univ.universityName}</b>{' '}
                You are applying for a university that has a higher-than-average success rate
              </li>
            ) : null}


            {(index === (row.length)-1) && !(findAnswer(7) >= univ.minIB) && !((findAnswer(12) >= univ.avergageUCATScore &&
                        univ.applicationTest == 'UCAT')) && !((findAnswer(11) >= univ.averageBMATScore &&
                                    univ.applicationTest == 'BMAT')) && !answerExist('18') &&
                !answerExist('19') &&
                !answerExist('Home') &&
                !answerExist('Comprehensive') &&
                !answerExist('Grammar School') &&
                !answerExist('Sixth Form College') &&
                !answerExist('Yes') ? (
                  <p>There is nothing in your selection that increases your success rate. We have calculated your results based on your selection, so, if you need any change in your results, please fill up the calculator again with different options.</p>  
                ) : null}


            {findAnswer(7) >= univ.minIB ? (
              <li style={{textIndent: '-1.5em',
                   marginLeft: '10px',
                paddingLeft: '1em'}}>
                <b>{findAnswer(7)}</b> {SixMed_CONTENT.IB_SCORE_MEET}
              </li>
            ) : null}
            
              
              {findAnswer(12) > univ.avergageUCATScore && findAnswer(12) < univ.averageSuccessfulUCATScore &&
            univ.applicationTest == 'UCAT' ?(
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b>{findAnswer(12)}</b> {SixMed_CONTENT.UCAT_SCORE_ABOVE_AVG}
                </li>
              ):null}
              {findAnswer(12) > univ.averageSuccessfulUCATScore &&
            univ.applicationTest == 'UCAT' ?(
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b>{findAnswer(12)}</b> <span>The UCAT score that you have entered is above the average successful score for this university - this puts you in an excellent position</span>
                </li>
              ):null}
              
            {findAnswer(11) >= univ.averageBMATScore &&
            univ.applicationTest == 'BMAT' ? (
              <li style={{textIndent: '-1.5em',
                   marginLeft: '10px',
              paddingLeft: '1em'}}>
                <b>{findAnswer(11)}</b> {SixMed_CONTENT.BMAT_SCORE_ABOVE_AVG}
              </li>
            ) : null}
          </>
        );
      })}
         
        <p>
       {answerExist('18') ? (
        <li style={{marginTop: '10px',textIndent: '-1.5em',
             marginLeft: '10px',
        paddingLeft: '1em'}}>
        <b>{'18'}</b> {SixMed_CONTENT.AGE_ABOVE_EIGTEEN}
          </li>
        ) : null}
       {answerExist('19') ? (
        <li style={{marginTop: '10px',textIndent: '-1.5em',
             marginLeft: '10px',
        paddingLeft: '1em'}}>
        <b>{'19'}</b> {SixMed_CONTENT.AGE_ABOVE_EIGTEEN}
          </li>
        ) : null}
        
        {answerExist('Home') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>Home</b> {SixMed_CONTENT.HOME_APPLICANT}
          </li>
        ) : null}
        {answerExist('Comprehensive') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE}
          </li>
        ) : null}
        {answerExist('Grammar School') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE}
          </li>
        ) : null}
        {answerExist('Sixth Form College') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE}
          </li>
        ) : null}
        {findAnswer(14) == 'Yes' ? (
         <li style={{marginTop: '10px',textIndent: '-1.5em',
              marginLeft: '10px',
         paddingLeft: '1em'}}>
          <span style={{fontWeight: '700'}}>Work Experience:</span> <b>{findAnswer(14)}</b> It’s great that you’ve managed to get some work experience in the field! It’s always a good idea to get more though as it gives you stuff to talk about in your Personal Statement and at interview.
        </li>
        ) : null}
      </p>

      <Typography
        variant='h4'
        mb={4}
        fontWeight='600'
        fontSize='30px'
        style={{ color: '#d82975' }}
      >
        Stuff that Decreases your success rate
      </Typography>
      <p>
        {universitiesScores.map((univ, index,row) => {
          return (
            <>

              {index > 0 ? (
                <>
                 {(findAnswer(7) < univ.minIB) && (findAnswer(12) < univ.avergageUCATScore &&
              univ.applicationTest == 'UCAT') && (findAnswer(11) < univ.averageBMATScore &&
              univ.applicationTest == 'BMAT')? (
                  <br />
                  ) : null}
                </>
              ) : null}
                <p>
                {(index === (row.length)-1) && !(findAnswer(7) < univ.minIB) && !(findAnswer(12) < univ.avergageUCATScore &&
                  univ.applicationTest == 'UCAT') && !(findAnswer(11) < univ.averageBMATScore &&
                  univ.applicationTest == 'BMAT') && !answerExist('International') && !answerExist('<18') &&
                  !answerExist('20') &&
                  !answerExist('FE & Tertiary College') &&
                  !answerExist('Independent') &&
                  !answerExist('Other') &&
                  !answerExist('No') ? (
                  <p>There is nothing in your selection that decreases your success rate. We have calculated your results based on your selection, so, if you need any change in your results, please fill up the calculator again with different options.</p>  
                ) : null}
               

              {(findAnswer(7) < univ.minIB) || (findAnswer(12) < univ.avergageUCATScore &&
              univ.applicationTest == 'UCAT') || (findAnswer(11) < univ.averageBMATScore &&
              univ.applicationTest == 'BMAT')? (
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b style={{ color: '#d82975' }}>{univ.universityName}</b>{' '}
                  You are applying for a university that has a higher-than-average success rate
                </li>
              ) : null}
              {findAnswer(7) < univ.minIB ? (
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b>{findAnswer(7)}</b> {SixMed_CONTENT.IB_SCORE_LOW}
                </li>
              ) : null}
              {findAnswer(12) < univ.avergageUCATScore &&
              univ.applicationTest == 'UCAT' ? (
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b>{findAnswer(12)}</b> {SixMed_CONTENT.UCAT_SCORE_BELOW_AVG}
                </li>
              ) : null}
              {findAnswer(11) < univ.averageBMATScore &&
              univ.applicationTest == 'BMAT' ? (
                <li style={{textIndent: '-1.5em',
                     marginLeft: '10px',
                paddingLeft: '1em'}}>
                  <b>{findAnswer(11)}</b> {SixMed_CONTENT.BMAT_SCORE_BELOW_AVG}
                </li>
              ) : null}
              </p>
            </>
          );
        })}
      </p>
      <p>
        {answerExist('International') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>International</b> {SixMed_CONTENT.INTERNATIONAL_APPLICANT}
          </li>
        ) : null}
        {answerExist('<18') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{'<18'}</b> {SixMed_CONTENT.AGE_UNDER_EIGNTEEN}
          </li>
        ) : null}
        {answerExist('20') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{'>19'}</b> {SixMed_CONTENT.AGE_ABOVE_NINETEEN}
          </li>
        ) : null}
        {answerExist('FE & Tertiary College') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE_LOW}
          </li>
        ) : null}
        {answerExist('Independent') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE_LOW}
          </li>
        ) : null}
        {answerExist('Other') ? (
          <li style={{marginTop: '10px',textIndent: '-1.5em',
               marginLeft: '10px',
          paddingLeft: '1em'}}>
            <b>{findAnswer(13)}</b> {SixMed_CONTENT.SCHOOL_TYPE_LOW}
          </li>
        ) : null}
       { findAnswer(14) == 'No' ? (
         <li style={{marginTop: '10px',marginTop: '15px',textIndent: '-1.5em',
              marginLeft: '10px',
         paddingLeft: '1em'}}>
           <span style={{fontWeight: '700'}}>Work Experience: </span><b>{findAnswer(14)}</b> It’s great that you’ve managed to get some work experience in the field! It’s always a good idea to get more though as it gives you stuff to talk about in your Personal Statement and at interview.
        </li>
        ) : null}
      </p>
      
      <Typography
        variant='h4'
        mb={4}
        fontWeight='600'
        fontSize='30px'
        style={{marginTop:universitiesScores?.length == 1 ? '650px': '20px', color: '#d82975'}}
      > 
        How does 6med calculate your success rate?
      </Typography>
      <p>
        This tool uses hundreds of data points gathered from sources such as the
        official university requirements, FOI (freedom of information) requests,
        past student data and our own insights to generate an estimated success
        rate based on your answers.
      </p>
      <p>
        The majority of this information comes directly from the universities
        themselves, as they are legally required to share this information under
        the FOI system.
      </p>
      <p>
        The success rate here is not an exact science (as much as we’d love it
        to be!), as there are many factors that go into your success rate, many
        of which can’t be measured quantitatively. The factors that can be
        estimated, have been estimated.
      </p>
      <p>
        Therefore, this is purely an estimation based on the data you have
        inputted compared versus the data points we have in our system. This is
        not a guarantee of any kind, but a mathematical formula devised to
        generate an estimated rate.
      </p>
      <p>
        Your success rate is, however, firmly grounded in real information and
        real data which our team have used to create this report to the very
        best of our knowledge.
      </p>

      <Typography
        variant='h4'
        mb={4}
        fontWeight='600'
        fontSize='30px'
        style={{ color: '#d82975' }}
      >
        How can we help you improve even more?
      </Typography>
      <p>
        6med’s award-winning support has helped over 10,000 students with their
        medical school applications since 2013.
      </p>
      <p>
        We offer a ton of support and resources to help you craft a winning
        application
        <p>
          {' '}
          –{' '}
          <a
            href='https://6med.co.uk/complete-medicine-preparation-bundle/'
            target='_blank'
          >
            a fan favourite is our Complete Bundle which gives you access to:
          </a>
        </p>
        <li>5x Crash Courses</li>
        <li>20+ Hours Of Video Content</li>
        <li>Access To BMAT.Ninja & UCAT.Ninja</li>
        <li>BMAT & Personal Statement Marking</li>
        <li>4x Workbooks</li>
      </p>
      <p>
        These cover the Personal Statement, UCAT, BMAT and Interview in-depth.
      </p>
      <p>
        If you aren’t looking for support for your entire application, but just
        want to focus on the Personal Statement, UCAT, BMAT or Interview, you
        can just get support for that area with the relevant bundle.
      </p>
      <p>Just click below for a link to the bundle you’re interested in:</p>
      <div>
        <li>
          <a href='https://6med.co.uk/personal-statement/bundle/'target='_blank'>
            Personal Statement Bundle
          </a>
        </li>
        <li>
          <a href='https://6med.co.uk/ucat/bundle/' target='_blank'>
            UCAT Bundle
          </a>
        </li>
        <li>
          <a href='https://6med.co.uk/bmat/bundle/' target='_blank'>
            BMAT Bundle
          </a>
        </li>
        <li>
          <a href='https://6med.co.uk/interview/bundle/' target='_blank'>
            Interview Bundle
          </a>
        </li>
      </div>
      <p>
        You can also check out our free resources over on{' '}
        <a href='https://6med.co.uk/guides/' target='_blank'>
          our guides!
        </a>
      </p>
      <br />
      <p>
        P.S. we offer a super generous bursary if you need some financial
        support. You can{' '}
        <a href='https://6med.co.uk/bursaries/' target='_blank'>
          check this out here.
        </a>
      </p>
    </div>
  );
}

export default Content;
